import {useCallback, useState} from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import {X, XCircle} from '@phosphor-icons/react';

import Action from './Action';

export type Props = {
  // eslint-disable-next-line no-unused-vars
  removeScheduledBot: (id: string) => void;
  disabled?: boolean;
  showInMenu: boolean;
  id: string;
};

export default function CancelRecordingAction({
  disabled = false,
  id,
  removeScheduledBot,
  showInMenu,
}: Props) {
  const [showRemoveBotConfirmationModal, setShowRemoveBotConfirmationModal] =
    useState<boolean>(false);

  const handleOpenRemoveBotConfirmationModal = useCallback(() => {
    setShowRemoveBotConfirmationModal(true);
  }, [setShowRemoveBotConfirmationModal]);

  const handleCloseRemoveBotConfirmationModal = useCallback(() => {
    setShowRemoveBotConfirmationModal(false);
  }, [setShowRemoveBotConfirmationModal]);

  return (
    <>
      <Action
        disabled={disabled}
        icon={<XCircle color="#EB5757" />}
        label="Cancel Recording"
        showInMenu={showInMenu}
        sx={{
          gap: 1,
          fontSize: '14px',
          color: '#EB5757',
          borderRadius: '8px',
          '.MuiListItemIcon-root': {
            minWidth: 0,
            svg: {
              width: '16px',
              height: '16px',
            },
          },
          paddingX: 1,
        }}
        onClick={() => {
          handleOpenRemoveBotConfirmationModal();
        }}
      />

      <Dialog fullWidth maxWidth="sm" open={showRemoveBotConfirmationModal}>
        <DialogTitle variant="h2">Cancel Recording</DialogTitle>
        <Box position="absolute" right={8} top={8}>
          <IconButton onClick={handleCloseRemoveBotConfirmationModal}>
            <X />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography gutterBottom>
            Are you sure you want to cancel the recording for your upcoming meeting?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="text"
            onClick={handleCloseRemoveBotConfirmationModal}
          >
            Cancel
          </Button>
          <Button
            color="warning"
            variant="contained"
            onClick={() => {
              removeScheduledBot(id);
              setShowRemoveBotConfirmationModal(null);
            }}
          >
            Don&apos;t Record Meeting
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
